import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, FormLabel } from "react-bootstrap";
import { ActivityContent } from "../component/CSR/Activity";
import { AxiosGet } from "../services/api/main";
import { useDispatch, useSelector } from "react-redux";
import { ShowLoading, HideLoading, SetDataNews } from "../redux/actions";

export default function CSRPage() {
  const dispatch = useDispatch();
  const selector_datanews = useSelector((state) => state.data_news);
  // const [lstData, setLstData] = useState([]);
  useEffect(() => {
    if (selector_datanews.length <= 0) {
      dispatch(ShowLoading());
      AxiosGet("mnews", null, (Result) => {
        dispatch(HideLoading());
        switch (Result.status) {
          case 200:
            dispatch(SetDataNews({ lstdata: Result.lstData }));
            // setLstData(Result.lstData);
            break;
          default:
            break;
        }
      });
    }
  }, []);

  return (
    <React.Fragment>
      <Container
        id="bodyCSR"
        fluid
        className="content-padding-top margin-bottom-content no-margin"
      >
        <Row className="margin-vertical-20px padding-30-px">
          <Col xs={12} sm={6} className="txt-align-center">
            <h1 className="txt-color-primary txt-weight-bold">
              ข่าวสารและกิจกรรม
            </h1>
          </Col>
        </Row>

        <ActivityContent lstData={selector_datanews} />
        {/* <ActElderlyCenter />
      <ActMask />
      <ActDonate />
      <ActTriPetch /> */}

        {/* <Row style={{ marginBottom: "50px" }}>
        <CarouselImg />
      </Row> */}
      </Container>
    </React.Fragment>
  );
}
