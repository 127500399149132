import styles from "styled-components";

const media = {
  mobile: `@media(max-width:575px)`,
  xxlg: `@media(min-width:1440px)`,
  xlg: `@media(min-width:1244px)`,
  lg: `@media(min-width:1024px)`,
  md: `@media(min-width:768px)`,
  s: `@media(min-width:480px)`,
  xs: `@media(min-width:320px)`,
};

const Box_VideoAndContent = styles.div`
    display:flex ;
    flex-direction:column ;
    padding:10px;
    padding-bottom:20px;
    border-bottom:5px solid #ed7d31; 
    div.Video-Content{
        flex:1;
        display:flex;
        justify-content:center;
        align-items:center;
        // padding:10px;
        
    }
    div.Video-Content iframe{
     flex:1;
    }
    div.Text-Content{
        flex:1;
        padding:20px;
        background-color:#f1f1f1;
        border-radius:10px;
        color:black;
    }
   

    ${media.xs}{
        div.Video-Content iframe{
            height:26vh;
         }
    }
    ${media.s}{
        div.Video-Content iframe{
            height:40vh;
         }
    }
    ${media.md}{
        div.Video-Content iframe{
            height:35vh;
         }
    }
    ${media.lg}{
        div.Video-Content iframe{
            height:34vh;
         }
    }
    ${media.xlg}{
        div.Video-Content iframe{
            height:64vh;
         }
    }
    ${media.xxlg}{
        div.Video-Content iframe{
            height:82vh;
            width:28cm;
            flex:none;
         }
    }
    

   
`;

export default Box_VideoAndContent;
