import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ShowLoading, HideLoading } from "../../redux/actions";
import {
  Container,
  Row,
  Col,
  Carousel,
  Button,
  Modal,
  Card,
} from "react-bootstrap";
import { AxiosGet } from "../../services/api/main";
import { GetImageFile } from "../../global/GlobalFunction";

export default function ColorGallery({ SProduct, isload }) {
  const [isShow, setIsShow] = useState(false);
  const [ListProductHis, SetListProductHis] = useState([]);
  const [ListHisTemp, SetListHisTemp] = useState([]);
  const [ListProductHisImgTemp, SetListProductHisImgTemp] = useState([]);
  const [ListProductHisImg, SetListProductHisImg] = useState([]);
  const dispatch = useDispatch();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const spId = params.get("sp");

  useEffect(() => {
    // console.log(SProduct.SProductTypeId);
    // console.log(spId);
    if (spId) {
      let findSp = ListHisTemp.find((f) => f.SProductTypeId === spId);
      // console.log("LoadData");
      if (findSp) {
        console.log(findSp);
        SetListProductHis(findSp.ListProductHis);
      } else {
        LoadData();
      }
    }
  }, [isload]);

  const LoadData = () => {
    dispatch(ShowLoading());
    AxiosGet(`producthistory?Order=asc&SProductTypeId=${spId}`, null, (req) => {
      switch (req.status) {
        case 200:
          SetListProductHis(req.lstData);
          let newObj = {
            SProductTypeId: spId,
            ListProductHis: req.lstData,
          };
          SetListHisTemp([...ListHisTemp, newObj]);
          //   var filter = req.lstData.filter((f) => f.MProductTypeId == mpId);
          break;
        case 500:
          console.log("error");
          break;
        default:
          break;
      }
      dispatch(HideLoading());
    });
  };

  const LoadDataImg = (id) => {
    dispatch(ShowLoading());
    AxiosGet(`producthistoryimg?ProductHistoryId=${id}`, null, (req) => {
      switch (req.status) {
        case 200:
          let newObj = {
            ProductHistoryId: id,
            ListImg: req.lstData,
          };
          // console.log(newObj);
          SetListProductHisImgTemp([...ListProductHisImgTemp, newObj]);
          SetListProductHisImg(req.lstData);
          setIsShow(true);

          // SetListProductHis(req.lstData);
          //   var filter = req.lstData.filter((f) => f.MProductTypeId == mpId);
          break;
        case 500:
          console.log("error");
          break;
        default:
          break;
      }
      dispatch(HideLoading());
    });
  };

  const ShowImgGallery = (id) => {
    let filterImg = ListProductHisImgTemp.find(
      (f) => f.ProductHistoryId === id
    );
    // console.log(filterImg);
    if (filterImg) {
      SetListProductHisImg(filterImg.ListImg);
      setIsShow(true);
    } else {
      LoadDataImg(id);
    }
  };

  const CardImgInfo = ({ data }) => {
    return (
      <Card className="margin-vertical-10px">
        {data.ProductHistoryImg ? (
          <Card.Img
            variant="top"
            src={GetImageFile(data.ProductHistoryImg)}
            style={{ height: "200px", objectFit: "cover" }}
          />
        ) : (
          <div style={{ height: "200px", backgroundColor: "#e6e6e6" }}> </div>
        )}
        <Card.Body>
          {/* <Card.Title>10ล้อ เหล็กสเเตนเลส พืชผลทางเกษตรกรรม</Card.Title> */}
          <Card.Title
            style={{
              height: "2m",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {data.ProductHistoryName}
          </Card.Title>
          <Card.Text
            style={{
              height: "3.6em",
              overflow: "hidden",
              whiteSpace: "pre-line",
            }}
          >
            {data.Description}
          </Card.Text>
          {/* <Card.Text>
            ตัวลูกยาว 6.70 เมตร ในส่วนของกระบะตัวแม่
            และตัวพ่วงได้มีการพัฒนาโดยการใช้เหล็กแบบพิเศษ
            เพื่อให้มีน้ำหนักเบาแต่ยังคงความแข็งแรง
          </Card.Text> */}
          <Button
            variant="primary"
            className="btn-primary-inline"
            onClick={() => {
              ShowImgGallery(data.ProductHistoryId);
            }}
          >
            รูปภาพเพิ่มเติม
          </Button>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div id="divScrollHeadGallery" className="my-2">
      {/* {ListProductHis.length > 0 ? (
        <h1 className="txt-color-primary">คลังรูปภาพลูกค้า</h1>
      ) : null} */}
      <Row id="divColorGallery" className="overflow-auto">
        {ListProductHis.length > 0
          ? ListProductHis.map((m, idx) => {
            return (
              <Col xs={12} sm={6} md={6} lg={6} key={`ProductHis${idx}`}>
                <CardImgInfo data={m} />
              </Col>
            );
          })
          : null}
        {/* <Col xs={12} sm={6} md={6} lg={3}>
          <CardImgInfo pic={PictureType1a} />
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <CardImgInfo pic={PictureType1a} />
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <CardImgInfo pic={PictureType1a} />
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <CardImgInfo pic={PictureType1a} />
        </Col>

        <Col xs={6} sm={6} md={6} lg={3}>
          <CardImgInfo pic={PictureType2a} />
        </Col>
        <Col xs={6} sm={6} md={6} lg={3}>
          <CardImgInfo pic={PictureType2a} />
        </Col>

        <Col xs={6} sm={6} md={6} lg={3}>
          <CardImgInfo pic={PictureType3a} />
        </Col>
        <Col xs={6} sm={6} md={6} lg={3}>
          <CardImgInfo pic={PictureType3a} />
        </Col> */}
      </Row>

      <Modal
        show={isShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setIsShow(false);
          SetListProductHisImg([]);
        }}
      >
        {/* <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Carousel>
            {/* <Carousel interval={2000}> */}
            {ListProductHisImg.map((m, idx) => {
              return (
                <Carousel.Item key={`ProductHis${idx}`}>
                  <img
                    className="d-block w-100"
                    src={GetImageFile(m.ProductHistoryImg)}
                    alt="Pic Slide"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      maxHeight: "500px",
                    }}
                  />
                </Carousel.Item>
              );
            })}
            {/* <Carousel.Item>
              <img
                className="d-block w-100"
                src={PictureType1a}
                alt="First slide"
                style={{ maxHeight: "500px" }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={PictureType1b}
                alt="Second slide"
                style={{ maxHeight: "500px" }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={PictureType1c}
                alt="Third slide"
                style={{ maxHeight: "500px" }}
              />
            </Carousel.Item> */}
            {/* <Carousel.Caption>
                <h3>Third slide label</h3>
                <p>
                  Praesent commodo cursus magna, vel scelerisque nisl
                  consectetur.
                </p>
              </Carousel.Caption> */}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
