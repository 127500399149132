import React, { useEffect, useState } from "react";
import "../style/main.scss";
import { Container, Row, Col, Table, Image } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import TypeMenu from "../component/ProductDetail/TypeMenu";
import NavProduct from "../component/ProductDetail/NavProduct";

import ColorGallery from "../component/ProductDetail/ColorGallery";
// import ButtonLayout from "../component/ProductDetail/ButtonLayout";

import { AxiosGet } from "../services/api/main";
import { useDispatch } from "react-redux";
import { ShowLoading, HideLoading } from "../redux/actions";
import { GetImageFile } from "../global/GlobalFunction";

// const useStyles = makeStyles((theme) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: "#fff",
//   },
// }));

export default function ProductDetailPage() {
  // const [tempEx, setTempEx] = useState(0);
  const [isload, setIsload] = useState(false);
  // const [colorEx, setColorEx] = useState(0);
  const dispatch = useDispatch();

  const [typeTruckTemp, SetTypeTruckTemp] = useState([]);
  const [ListSProductType, fnListSProductType] = useState([]);
  const [ListSProductTypeImage, fnListSProductTypeImage] = useState([]);
  const [SProduct, fnSProduct] = useState(null);
  const [SProductImg, fnSProductImg] = useState(null);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const spId = params.get("sp");
  const mpId = params.get("mp");

  // let { type } = useParams();

  useEffect(() => {
    // console.log("mp : " + mpId, "sp : " + spId);

    if (mpId && spId) {
      let find = typeTruckTemp.find((f) => f.spId === spId);
      // console.log("load");
      if (find) {
        if (SProduct.SProductTypeId === spId) return;
        fnListSProductTypeImage(find.listImg);
        fnSProductImg(find.listImg[0]);
        fnSProduct(find.spData);
      } else {
        LoadSProductType();
      }
    }
  }, [isload]);

  const LoadSProductType = () => {
    dispatch(ShowLoading());
    AxiosGet(`sproducttype`, null, (req) => {
      switch (req.status) {
        case 200:
          var filter = req.lstData.filter((f) => f.MProductTypeId == mpId);
          fnListSProductType(filter);

          let findSP = filter.find((f) => f.SProductTypeId == spId);
          LoadSProductTypeIamge(findSP);
          fnSProduct(findSP);
          break;
        case 500:
          console.log("error");
          break;
        default:
          break;
      }
    });
  };

  const LoadSProductTypeIamge = (sproduct) => {
    AxiosGet(`sproducttypeimg?SProductTypeId=${spId}`, null, (req) => {
      switch (req.status) {
        case 200:
          // console.log(req.lstData);
          // var filter = req.lstData.filter((f) => f.MProductTypeId == mpId);
          let savetemp = {
            spId: spId,
            spData: sproduct,
            listImg: req.lstData,
          };
          typeTruckTemp.push(savetemp);
          SetTypeTruckTemp(typeTruckTemp);
          fnListSProductTypeImage(req.lstData);
          fnSProductImg(req.lstData[0]);
          break;
        case 500:
          console.log("error");
          break;
        default:
          break;
      }
      dispatch(HideLoading());
    });
  };

  const OnChangeColor = (evt, item) => {
    document
      .querySelector("[name='CircleTruckColor'].active")
      .classList.remove("active");
    evt.currentTarget.classList.add("active");

    let typeimg = ListSProductTypeImage.find(
      (f) => f.SProductTypeImgId === item.SProductTypeImgId
    );

    fnSProductImg(typeimg);

    // setSide(side != 0 ? 0 : side);
    // setColorIdx(idx);

    // setColorEx(idx);
  };

  return (
    <Container
      fluid
      className="content-padding-top margin-bottom-content no-margin"
    >
      {/* {ListSProductType && ListSProductType.length > 0 ? (
        <NavProduct
          // ListSProductType={ListSProductType}
          // choosedId={spId}
          SProduct={SProduct}

          // tempEx={tempEx}
        />
        ) : null} */}
      <NavProduct SProduct={SProduct} />

      <TypeMenu
        ListSProductType={ListSProductType}
        choosedId={spId}
        setIsload={() => setIsload((val) => !val)}
      />
      {/* {ListSProductType && ListSProductType.length > 0 ? (
      ) : null} */}

      <Container>
        <Row>
          {SProduct ? (
            <h2 className="txt-color-primary txt-weight-bold ">
              {SProduct.SProductTypeName}
            </h2>
          ) : (
            ""
          )}
        </Row>
        {/* <Row
          className="margin-vertical-20px padding-15-px"
          style={{ borderTop: "5px solid #f3a875" }}
        >
          <Col xs={6}>
            <h1 className="txt-color-primary">{typeTruck}</h1>
            <label>รายละเอียดนิดหน่อย</label>
          </Col>
          <Col xs={6} className="txt-align-right">
            <h5>ราคาเริ่มต้น</h5>
            <h5>
              <label className="padding-5-px bg-color-primary txt-color-white">
                1,000,000 บาท
              </label>
            </h5>
          </Col>
        </Row> */}
        <Row
          id="divScrollHeadInfo"
          className="margin-vertical-20px justify-content-center"
        >
          {SProductImg ? (
            <Image
              className="imageslide"
              src={GetImageFile(SProductImg.SProductTypeImg)}
              loading="lazy"
              draggable={false}
            />
          ) : null}

          <Col
            xs={12}
            className="margin-vertical-20px"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {ListSProductTypeImage && SProductImg
              ? ListSProductTypeImage.map((item, idx) => {
                return (
                  <label
                    className={
                      "circle-color-lbl" +
                      (SProductImg.SProductTypeImgId ===
                        item.SProductTypeImgId
                        ? " active"
                        : "")
                    }
                    name="CircleTruckColor"
                    onClick={(e) => OnChangeColor(e, item)}
                    key={idx}
                  >
                    <span
                      className="circle-color"
                      style={{ backgroundColor: item.Colors }}
                    ></span>
                  </label>
                );
              })
              : null}
          </Col>
        </Row>

        <Row>
          <h1 className="txt-color-primary">รูปรถตัวอย่าง</h1>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Row id="divScrollHeadSpec" className="my-2">
              {SProduct && SProduct.SProductSpecImg ? (
                <Col xs={12} className="">
                  <Container className="txt-align-center">
                    <Image src={GetImageFile(SProduct.SProductSpecImg)} fluid />
                    {/* <Image src={TruckSpecPic} fluid /> */}
                  </Container>
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            {SProduct && SProduct.SProductTypeImgMenu ? (
              // <ProductHistory SProduct={SProduct} />
              <ColorGallery SProduct={SProduct} isload={isload} />
            ) : null}
          </Col>
        </Row>

        <Row className="margin-vertical-30px">
          <Col xs={12} className="">
            <h1 className="txt-color-primary">ข้อมูลรถเบื้องต้น</h1>
          </Col>
          <Col>
            <Container id="divScrollHeadDetail">
              {SProduct && SProduct.SProductDescription ? (
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: SProduct.SProductDescription,
                  }}
                ></div>
              ) : null}
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
