import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
// import MainBand1 from "../../img/Home_Img.jpg";
// import MainBand2 from "../../img/Ads/TailerAds2.jpg";
// import MainBand3 from "../../img/Ads/ad1.jpg";
// import MainBand6 from "../../img/Ads/ad4.jpg";
// import MainBand7 from "../../img/Ads/ad5.jpg";
// import MainBand8 from "../../img/Ads/ad6.jpg";
// import MainBand9 from "../../img/Ads/ad72.jpg";
import { ImgMain } from "./ContainerImg";
import { useDispatch } from "react-redux";
import { ShowLoading, HideLoading } from "../../redux/actions";
import { AxiosGet } from "../../services/api/main";
import { useState } from "react";
import { GetImageFile } from "../../global/GlobalFunction";
// import MainBand from "../img/Home_Img.jpg";

export default function PicMainSlide() {
  const [ArrHomePic, SetArrHomePic] = useState([]);
  const dispatch = useDispatch();

  const GetHomePic = () => {
    AxiosGet(`homepic`, null, (req) => {
      switch (req.status) {
        case 200:
          // let newObj = {
          //   ProductHistoryId: id,
          //   ListImg: req.lstData,
          // };
          SetArrHomePic(req.lstData);
          // console.log(req.lstData);

          break;
        case 500:
          console.log("error");
          break;
        default:
          break;
      }
      dispatch(HideLoading());
    });
  };

  useEffect(() => {
    GetHomePic();
  }, []);

  return (
    <Carousel interval={5000} className="p-0 carousel-div">
      {ArrHomePic.map((item, idx) => (
        <Carousel.Item key={idx} style={{ zIndex: 0 }}>
          <ImgMain src={GetImageFile(item.homepicPath)} alt="" />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
