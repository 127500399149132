import React, { useEffect } from "react";
import MainBand from "../img/service.jpg";
import ServPic1 from "../img/ServicesPic/service1.jpg";
import ServPic2 from "../img/ServicesPic/service2.jpg";
import ServPic3 from "../img/ServicesPic/service3.jpg";
// import ServPic4 from "../img/ServicesPic/service4.jpg";
// import ServPic5 from "../img/ServicesPic/service5.jpg";
// import ServPic6 from "../img/ServicesPic/service6.JPG";
import { Container, Row, Col, Image, FormLabel } from "react-bootstrap";
import anime from "animejs/lib/anime.es.js";

export default function ServicePage() {
  useEffect(() => {
    anime({
      targets: "#bodyService",
      opacity: "100%",
      easing: "linear",
    });
  }, []);

  return (
    <Container
      id="bodyService"
      fluid
      style={{ paddingTop: "110px", minHeight: "71.5vh" }}
      className="no-margin "
    >
      <Row className="margin-vertical-20px  padding-30-px">
        <h1 className="txt-color-primary txt-weight-bold text-center">
          บริการหลังการขายและซ่อมบำรุง
        </h1>
      </Row>

      <Row className="px-5">
        <Col xs={12} sm={4}>
          <Image src={ServPic1} thumbnail />
        </Col>
        <Col xs={12} sm={4}>
          <Image src={ServPic2} thumbnail />
        </Col>
        <Col xs={12} sm={4}>
          <Image src={ServPic3} thumbnail />
        </Col>
      </Row>

      <Row className="pt-5">
        <Col xs={12}>
          <Container>
            <h4 className="text-start">
              บริษัทฯ ให้บริการซ่อมบำรุงหัวลาก และรถกระบะบรรทุกทุกประเภท
              ตามมาตรฐานอู่สหะกิจ
              ด้วยประสบการณ์ด้านการซ่อมบำรุงและการบริการหลังการขายกว่า 30 ปี
              โดยช่างมืออาชีพที่เข้าใจทุกองค์ประกอบของรถคุณ เพื่อให้แน่ใจว่า
              รถบรรทุกของท่านยังคงความแข็งแรงทนทาน สามารถใช้งานได้อย่างยาวนาน
              และไม่หวั่นแม้ต้องลุยงานหนัก
              <br />
              <br />
              ประเภทการซ่อมที่ทางบริษัทฯ ให้บริการ มีดังนี้
              <br />
              <br />
              1.การซ่อมแซมส่วนที่เสียหายจากอุบัติเหตุ (Accident Maintenance) –
              บริการซ่อมรถบรรทุก ครอบคลุม โครงสร้างตัวรถ ช่วงล่าง หางพ่วง
              กระบะทั้งตัวแม่และตัวพ่วง ตัวถังและอะไหล่ต่างๆ
              กรณีเกิดอุบัติเหตุที่อนุมัติโดยบริษัทประกันภัย
              และกรณีลูกค้าสำรองจ่ายเอง
              <br />
              <br />
              2.การบำรุงรักษาหรือเชคสภาพตามแผนและเชิงป้องกัน (Preventive and
              Proactive Maintenance) – บริการงานซ่อมบำรุง การเช็คระยะ
              การดูแลรักษารถบรรทุกหลังการใช้งาน เช่น
              การเปลี่ยนอะไหล่ตามระยะทางการใช้งาน
            </h4>
          </Container>
        </Col>
      </Row>

      <Row className="margin-vertical-20px  padding-30-px">
        <Col xs={12} sm={6} className="txt-align-center">
          <h1 className="txt-color-primary txt-weight-bold">
            บริการประกันภัยรถบรรทุก
          </h1>
        </Col>
      </Row>
      <Row className="padding-side-10-percent margin-vertical-20px padding-30-px flex-warp-reverse-mobile-only ">
        <Col xs={12} sm={5}>
          <div className="halfboxcontent-image-box">
            <Image
              className="halfboxcontent-image no-padding object-fit-fill border-radius-10-px margin-bottom-10-px"
              src={MainBand}
              fluid
            />
          </div>
        </Col>
        <Col xs={12} sm={7}>
          <Container className="halfboxcontent-text flex-all-center bg-color-lightshadow border-radius-10-px padding-15-px">
            <h4>
              บริษัทฯ เสนอหลากหลายบริการทางการเงิน
              ที่มีทางเลือกในการวางเงินดาวน์ และการผ่อนชำระที่เหมาะสมกับลูกค้า
              สนใจสอบถามรายละเอียดเพิ่มเติมได้ที่ 036-371-011 หรือ 036-371-849
            </h4>
          </Container>
        </Col>
      </Row>
      {/* 
      <Row className="margin-vertical-20px  padding-30-px">
        <Col xs={12} sm={6} className="txt-align-center">
          <h1 className="txt-color-primary txt-weight-bold">บริการประกันภัย</h1>
        </Col>
      </Row>
      <Row className="padding-side-10-percent margin-vertical-20px padding-30-px flex-warp-reverse-mobile-only ">
        <Col xs={12} sm={5}>
          <div className="halfboxcontent-image-box">
            <Image
              className="halfboxcontent-image no-padding object-fit-fill border-radius-10-px margin-bottom-10-px"
              src={MainBand}
              fluid
            />
          </div>
        </Col>
        <Col xs={12} sm={7}>
          <Container className="halfboxcontent-text flex-direct-column flex-all-center border-radius-10-px padding-15-px">
            <label className="">
              บริษัทฯ ให้ความสนใจกับการบริการหลังการขายอย่างครบวงจร
              เพื่อสร้างคุณภาพความปลอดภัย ความน่าเชื่อถือ
              และความคุ้มครอบที่ดีที่สุดให้กับรถบรรทุกของคุณ
              เรามีแผนประกันภัยที่เหมาะสม และเป็นมาตรฐาน รับประกันโดย
              วิริยะประกันภัย ที่มีความคุ้มครองอย่างรอบด้านในกรณีเกิดอุบัติเหตุ
              สนใจสอบถามรายละเอียดเพิ่มเติมได้ที่ 036-371-011 หรือ 036-371-849
            </label>
          </Container>
        </Col>
      </Row> */}

      {/* <Row className="margin-vertical-20px  padding-30-px">
        <Col xs={12} sm={6} className="txt-align-center">
          <h1 className="txt-color-primary txt-weight-bold">บริการซ่อมบำรุง</h1>
        </Col>
      </Row>
      <Row className="padding-side-10-percent margin-vertical-20px padding-30-px flex-warp-reverse-mobile-only ">
        <Col xs={12} sm={7}>
          <Container className="halfboxcontent-text flex-all-center bg-color-lightshadow border-radius-10-px padding-15-px">
            <label>
              บริษัทฯ ให้บริการซ่อมบำรุงหัวลาก และรถกระบะบรรทุกทุกประเภท
              ตามมาตรฐานอู่สหะกิจ
              ด้วยประสบการณ์ด้านการซ่อมบำรุงและการบริการหลังการขายกว่า 30 ปี
              โดยช่างมืออาชีพที่เข้าใจทุกองค์ประกอบของรถคุณ เพื่อให้แน่ใจว่า
              รถบรรทุกของท่านยังคงความแข็งแรงทนทาน สามารถใช้งานได้อย่างยาวนาน
              และไม่หวั่นแม้ต้องลุยงานหนัก
            </label>
          </Container>
        </Col>
        <Col xs={12} sm={5}>
          <div className="halfboxcontent-image-box">
            <Image
              className="halfboxcontent-image no-padding object-fit-fill border-radius-10-px margin-bottom-10-px"
              src={MainBand}
              fluid
            />
          </div>
        </Col>
      </Row> */}
    </Container>
  );
}
