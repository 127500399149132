import React from "react";
import { Container, Row, Col, FormLabel } from "react-bootstrap";
import { faPhoneAlt, faEnvelope, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faLine } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Footers() {
  return (
    <Container className="no-padding margin-top-10-px" fluid>
      <Container
        fluid
        className="no-margin padding-15-px"
        style={{
          backgroundColor: "#313131",
          color: "white",
          borderBottom: "2px solid #616161",
          padding: "12px",
        }}
      >
        <Row className="no-margin ">
          <Col xs={12} sm={6} md={3} >
            <Row>
              <FormLabel style={{ width: "100%" }}>ติดต่อเรา</FormLabel>
              <FormLabel style={{ marginRight: "10px" }}>
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  style={{ fontSize: "14px", marginRight: "10px" }}
                />
                036-371-011, 036-370351-55
              </FormLabel>
              <FormLabel>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ fontSize: "14px", marginRight: "10px" }}
                />
                sahakit_1989@hotmail.com
              </FormLabel>
              <FormLabel>
                <FontAwesomeIcon
                  icon={faLocationArrow}
                  style={{ fontSize: "14px", marginRight: "10px" }}
                />
                AOO SAHAKIT NONGKHAE CO.,LTD.
                <br />29 Moo 11, Nong Khai Nam,
                <br />Nong Khae District, Saraburi 18140
              </FormLabel>
            </Row>
          </Col>
          <Col xs={12} sm={6} md={3} >
            <Row>
              <FormLabel style={{ width: "100%" }}>ติดตามเรา</FormLabel>
              <FormLabel
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.facebook.com/sahakit1989")
                }
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  style={{ fontSize: "14px", marginRight: "10px" }}
                />
                Sahakit - อู่สหะกิจ หนองแค
              </FormLabel>
              <FormLabel
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://lin.ee/3NsXCD1")
                }
              >
                <FontAwesomeIcon
                  icon={faLine}
                  style={{ fontSize: "14px", marginRight: "10px" }}
                />
                @Sahakit
              </FormLabel>
            </Row>
          </Col>
          <Col xs={12} sm={6} md={3} >
            <FormLabel style={{ width: "100%" }}>
              <li>
                <a href="/AboutUs" className="txt-color-white">
                  เกี่ยวกับเรา
                </a>
              </li>
            </FormLabel>
            <FormLabel style={{ width: "100%" }}>
              <li>
                <a href="/CSR" className="txt-color-white">
                  ข่าวสารและกิจกรรม
                </a>
              </li>
            </FormLabel>
          </Col>
          <Col xs={12} sm={6} md={3} >
            <FormLabel style={{ width: "100%" }}>
              <li>
                <a href="/Service" className="txt-color-white">
                  บริการ
                </a>
              </li>
            </FormLabel>
            <FormLabel style={{ width: "100%" }}>
              <li>
                <a href="/Contact" className="txt-color-white">
                  ติดต่อเรา
                </a>
              </li>
            </FormLabel>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="no-margin"
        style={{
          backgroundColor: "#474646",
          padding: "5px ",
          color: "#f7f7f7",
        }}
      >
        <FormLabel
          style={{ textAlign: "center", width: "100%", fontSize: "14px" }}
        >
          Copyright © 2022 Sahakit 1989
        </FormLabel>
      </Container>
    </Container>
  );
}
