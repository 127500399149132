import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Col, Image, Row } from "react-bootstrap";
import { AxiosGet } from "../../services/api/main";
import { GetImageFile } from "../../global/GlobalFunction";

export default function TruckList() {
  const [ListSP, SetListSP] = useState([]);
  const [ListMP, SetListMP] = useState([]);
  const history = useHistory();

  useEffect(() => {
    // if (ListSP.length > 0 && ListMP.length > 0) {
    //   console.log("ListSP", ListSP);
    //   console.log("ListMP", ListMP);
    // } else {
    //   setTimeout(() => {
    // }, 5000);
    // }
    LoadProductType();
  }, []);

  const LoadProductType = () => {
    AxiosGet(`sproducttype`, null, (req) => {
      switch (req.status) {
        case 200:
          SetListSP(req.lstData);
          break;
        case 500:
          console.log("error");
          break;
        default:
          break;
      }
    });
    AxiosGet(`mproducttype`, null, (req) => {
      switch (req.status) {
        case 200:
          SetListMP(req.lstData);
          break;
        case 500:
          console.log("error");
          break;
        default:
          break;
      }
    });
  };

  return (
    <div>
      <Row className="px-2 px-sm-5">
        {ListSP.length > 0 && ListMP.length > 0
          ? ListMP.map((item, idx) => {
            let filterSP = ListSP.filter(
              (f) => f.MProductTypeId === item.MProductTypeId
            );

            return filterSP.map((sp, index) => {
              // if (index === 3) return;
              return (
                <Col className="p-2" xs={6} sm={4} key={index}>
                  <div
                    className="div-image-about"
                    onClick={() => {
                      //   ProductDetail/?mp=9&sp=4
                      history.push(
                        `/ProductDetail?mp=${sp.MProductTypeId}&sp=${sp.SProductTypeId}`
                      );
                    }}
                  >
                    <Image
                      className="image-about"
                      src={GetImageFile(sp.SProductTypeImgMenu)}
                      thumbnail
                    />
                    <label className="text-center fw-bold text-image-about">
                      {item.MProductTypeName} {sp.SProductTypeName}
                    </label>
                  </div>
                </Col>
              );
            });

            //   let mpObjName = ListMP.find(
            //     (f) => f.MProductTypeId === item.MProductTypeId
            //   ).MProductTypeName;
            //   return (
            //     <Col className="p-2" xs={6} sm={4} key={idx}>
            //       <div
            //         className="div-image-about"
            //         onClick={() => {
            //           //   ProductDetail/?mp=9&sp=4
            //           history.push(
            //             `/ProductDetail?mp=${item.MProductTypeId}&sp=${item.SProductTypeId}`
            //           );
            //         }}
            //       >
            //         <Image
            //           className="image-about"
            //           src={atob(item.SProductTypeImgMenu)}
            //           thumbnail
            //         />
            //         <h5 className="text-center fw-bold">
            //           {mpObjName} {item.SProductTypeName}
            //         </h5>
            //       </div>
            //     </Col>
            //   );
          })
          : null}

        {/* <Col className="p-2" xs={6} sm={4}>
          <div className="div-image-about">
            <Image className="image-about" src={Img2} thumbnail />
            <h5 className="text-center fw-bold">10ล้อ อลูมิเนียม หินทราย</h5>
          </div>
        </Col>
        <Col className="p-2" xs={6} sm={4}>
          <div className="div-image-about">
            <Image className="image-about" src={Img3} thumbnail />
            <h5 className="text-center fw-bold">10ล้อ เหล็ก พืชผลเกษตรกรรม</h5>
          </div>
        </Col>
        <Col className="p-2" xs={6} sm={4}>
          <div className="div-image-about">
            <Image className="image-about" src={Img4} thumbnail />
            <h5 className="text-center fw-bold">10ล้อ เหล็ก หินทราย</h5>
          </div>
        </Col>
        <Col className="p-2" xs={6} sm={4}>
          <div className="div-image-about">
            <Image className="image-about" src={Img5} thumbnail />
            <h5 className="text-center fw-bold">12ล้อ เหล็ก พืชผลเกษตรกรรม</h5>
          </div>
        </Col>
        <Col className="p-2" xs={6} sm={4}>
          <div className="div-image-about">
            <Image className="image-about" src={Img6} thumbnail />
            <h5 className="text-center fw-bold">6ล้อ อลูมิเนียม หินทราย</h5>
          </div>
        </Col> */}
      </Row>
    </div>
  );
}
