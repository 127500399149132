export const GetOs = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }
  return os;
};

export const convertDateFromApi = (sDateApi) => {
  let resultDate;
  let splitDateTime = sDateApi.split(" ");
  let splitDate = splitDateTime[0].split("-");
  let splitTime = splitDateTime[1].split(":");
  resultDate = new Date(
    splitDate[0],
    parseInt(splitDate[1]) - 1,
    splitDate[2],
    splitTime[0],
    splitTime[1],
    splitTime[2]
  );
  return resultDate;
};

export const convertDateTimeToString2Digit = (dDate) => {
  let stringResult =
    ("0" + dDate.getDate()).slice(-2) +
    "/" +
    ("0" + dDate.getMonth()).slice(-2) +
    "/" +
    dDate.getFullYear() +
    " " +
    ("0" + dDate.getHours()).slice(-2) +
    ":" +
    ("0" + dDate.getMinutes()).slice(-2);
  return stringResult;
};

export const convertDateTimeToStringDate = (dDate) => {
  let stringResult =
    ("0" + dDate.getDate()).slice(-2) +
    "/" +
    ("0" + dDate.getMonth()).slice(-2) +
    "/" +
    dDate.getFullYear();
  return stringResult;
};

export function GetImageFile(pPathFile) {
  return `${process.env.REACT_APP_API_URL}api/upload/${pPathFile}`;
}
