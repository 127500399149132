import React, { useEffect } from "react";
import { Container, Row, Col, Image, FormLabel } from "react-bootstrap";
import MainBand from "../img/contact_us.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faLine } from "@fortawesome/free-brands-svg-icons";
import { faPhoneAlt, faEnvelope, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import mapboxgl from "mapbox-gl";
import anime from "animejs/lib/anime.es.js";

export default function ContactPage() {
  /* ----------- MapBox ----------- */
  // useEffect(() => {
  //   mapboxgl.accessToken =
  //     "pk.eyJ1Ijoic2FoYXBhdCIsImEiOiJja3B6ZnhuMmMwZzh2MnFtYjY2eHhoM2FpIn0.aG8rw2pMSFhmHauUojIc0Q";
  //   var map = new mapboxgl.Map({
  //     container: "conMap",
  //     style: "mapbox://styles/mapbox/streets-v11",
  //     center: [100.8717511, 14.3539523],
  //     zoom: 14,
  //     terrain: {
  //       source: "mapbox-raster-dem",
  //       exaggeration: 2,
  //     },
  //   });

  //   var marker1 = new mapboxgl.Marker()
  //     .setLngLat([100.8717511, 14.3539523])
  //     .addTo(map);

  //   anime({
  //     targets: "#bodyContact",
  //     opacity: "100%",
  //     easing: "linear",
  //   });
  // }, []);
  /* ----------- MapBox ----------- */

  return (
    <Container
      id="bodyContact"
      fluid
      style={{ paddingTop: "110px" }}
      className="no-margin "
    >
      {/* <Row className=" padding-30-px">
        <Col xs={12} sm={6} className="txt-align-center">
          <h1 className="txt-color-primary txt-weight-bold">ที่อยู่</h1>
        </Col>
      </Row> */}
      <Row className="padding-30-px">
        <Col xs={12} sm={7} md={8} lg={9} className="  margin-bottom-10-px">
          {/* ----------- MapBox ----------- */}
          {/* <div id="conMap" style={{ height: "442px" }}></div> */}
          {/* ----------- MapBox ----------- */}
          <iframe
            id="mapContact"
            title="mapContact"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d912.0826059356439!2d100.8718467250862!3d14.353709192893563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d93ebffffff93%3A0x576b4bdb094ffc9d!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4reC4ueC5iOC4quC4q-C4sOC4geC4tOC4iCAoMTk4OSkg4LiI4Liz4LiB4Lix4LiU!5e0!3m2!1sth!2sth!4v1626974642400!5m2!1sth!2sth"
            style={{ width: "100%", height: "100%" }}
            // allowfullscreen=""
            loading="lazy"
          ></iframe>
        </Col>
        <Col xs={12} sm={5} md={4} lg={3} className="  margin-bottom-10-px">
          <Container
            className="bg-color-primary border-radius-10-px padding-15-px margin-bottom-10-px height-box-address"
          // style={{ height: "400px" }}
          >
            <FormLabel
              className="mb-4"
              style={{ color: "white", width: "100%" }}
            >
              <FontAwesomeIcon
                icon={faLocationArrow}
                style={{ fontSize: "20px", width: "20px" }}
              />
              {" "}AOO SAHAKIT NONGKHAE CO.,LTD. <br />
              29 Moo 11, Nong Khai Nam, <br />
              Nong Khae District, Saraburi 18140
            </FormLabel>
            <FormLabel
              className="mb-4"
              style={{ color: "white", width: "100%" }}
            >
              <FontAwesomeIcon
                icon={faPhoneAlt}
                style={{ fontSize: "20px", width: "20px" }}
              />{" "}
              036-371-011, 036-370351-55
            </FormLabel>
            <FormLabel
              className="mb-4"
              style={{ color: "white", width: "100%", cursor: "pointer" }}
              onClick={() =>
                window.open("https://www.facebook.com/sahakit1989")
              }
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                style={{
                  fontSize: "20px",
                  width: "20px",
                }}
              />
              Sahakit - อู่สหะกิจ หนองแค
            </FormLabel>
            <FormLabel
              className="mb-4"
              style={{ color: "white", width: "100%" }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{
                  fontSize: "20px",
                  width: "20px",
                }}
              />{" "}
              sahakit_1989@hotmail.com
            </FormLabel>
            <FormLabel
              className="mb-4"
              style={{ color: "white", width: "100%" }}
            >
              <FontAwesomeIcon
                icon={faLine}
                style={{
                  fontSize: "20px",
                  width: "20px",
                }}
              />{" "}
              @Sahakit
            </FormLabel>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
