import { SHOW_LOADING, HIDE_LOADING,SET_DATA_NEWS,SET_DATA_PRODUCT_MENU,SET_DATA_SPRODUCT_MENU } from './actions_type'


export const ShowLoading = (payload) => {
    return {
        type: SHOW_LOADING, payload
    }
};
export const HideLoading = (payload) => {
    return {
        type: HIDE_LOADING, payload
    }
};

export const SetDataNews = (payload) => {
    return {
        type: SET_DATA_NEWS, payload
    }
};

export const SetDataProductMenu = (payload) => {
    return {
        type: SET_DATA_PRODUCT_MENU, payload
    }
};

export const SetDataSProductMenu = (payload) => {
    return {
        type: SET_DATA_SPRODUCT_MENU, payload
    }
};