import styled from "styled-components";

const media = {
  smaller: `@media (min-width:576px) and (max-width:1000px)`,
  big_mobile: `@media (min-width:576px) `,
  mobile: `@media (max-width:576px)`,
  tablet: `@media (max-width:992px)`,
};

export const ActivityBlock = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  .container{
    border-radius: 10px;
  }

  .bg-lightshadow {
    background-color: #f1f1f1;
  }

  .content-block,
  .img-block {
    height: 100%;
    padding: 5%;
  }

  .img-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    letter-spacing: 1px;
  }

  .content-block h1 {
    font-weight: bold;
  }

  .content-block p,
  .content-block b {
    font-size: 20px;
  }

  .img {
    max-width: 100%;
    width: 100%;
    border-radius: 5px;
  }

  .revert-div {
    direction: rtl;
  }

  .revert-div .content-block {
    text-align: left;
    direction: ltr;
  }

  ${media.mobile} {
    .img {
      min-width: 100%;
    }
  }
`;
