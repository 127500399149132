import styled from "styled-components";

const media = {
  smaller: `@media (min-width:576px) and (max-width:1000px)`,
  big_mobile: `@media (min-width:576px) `,
  mobile: `@media (max-width:576px)`,
  tablet: `@media (max-width:992px)`,
};

export const ImgLogo = styled.img`
  width: 70px;
  height: 60px;
  margin-left: 15vh;

  ${media.big_mobile} {
    margin-left: 0;
  }

  ${media.mobile} {
    margin-left: 0;
  }
`;

export const ProductDetailNav = styled.div`
  width: 100%;
  height: 50px;
  overflow: hidden;
  background-color: #505050;
  color: white;

  .box-content {
    height: 100%;
    width: 75%;
    margin: auto;
    display: flex;
  }
  
  ${media.mobile} {
    padding-left:0px!important;
    padding-right:0px!important;

    .box-content {
      width: 100%!important;
    }
  }
  ${media.tablet} {
    .box-content {
      width: 80%;
    }
  }

    .div-text-head {
      font-size: 1.2em;
    }

    .div-text {
      font-size: 14px;
      padding-right: 10px !important;
      padding-left: 10px !important;
      font-weight: lighter;
        

      ${media.mobile} {
          padding-left:5px!important;
          padding-right:5px!important;
      }
      
    }
  }

  .div-text,
  .div-text-head {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    border-right: 2px #bebebe solid;
  }

  .div-text {
    cursor: pointer;
    font-weight: bold;
    transition: all 0.5s;



  }
  .div-text:hover {
    color: #ed7d31;
  }
`;
