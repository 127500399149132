import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../img/logoSHK.png";
import {
  BoxRowCategory,
  ImgLogo,
  CompanyName,
  DropDownMobile,
} from "./NavbarStyleComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

// axios
import { AxiosGetAsync, AxiosGet } from "../../services/api/main";
import { useSelector, useDispatch } from "react-redux";
import {
  SetDataProductMenu,
  SetDataSProductMenu,
  ShowLoading,
  HideLoading,
} from "../../redux/actions";
import { GetImageFile } from "../../global/GlobalFunction";

export default function NavbarComponent() {
  const [isShow, fnIsShow] = useState(false);
  const [iconProduct, fnIconProduct] = useState(faChevronDown);
  const [isChangePath, fnChangePath] = useState(false);
  const [selTypeProduct, fnSelectType] = useState(0);
  // const [MProductType, fnMProductType] = useState([]);
  // const [MProductTypeSide, fnMProductTypeSide] = useState([]);
  // const [SProductType, fnSProductType] = useState([]);
  let isToggleNav = false;
  const location = useLocation();
  const dispatch = useDispatch();
  const selector_data_product = useSelector((state) => state.data_product_menu);
  const selector_data_sproduct = useSelector(
    (state) => state.data_sproduct_menu
  );
  const getDataMproductType = () => {
    dispatch(ShowLoading());
    AxiosGet("mproducttype", null, (req) => {
      switch (req.status) {
        case 200:
          dispatch(SetDataProductMenu({ lstdata: req.lstData }));
          if (selector_data_sproduct.length <= 0) {
            getDataSproductType();
          }
          break;
        default:
          console.log("error");
          break;
      }
    });
  };

  const getDataSproductType = () => {
    dispatch(ShowLoading());
    AxiosGet("sproducttype", null, (req) => {
      dispatch(HideLoading());
      switch (req.status) {
        case 200:
          dispatch(SetDataSProductMenu({ lstdata: req.lstData }));
          break;
        case 500:
          console.log("error");
          break;
        default:
          break;
      }
    });
  };

  useEffect(() => {
    // GetMenu();
    if (selector_data_product.length <= 0) {
      getDataMproductType();
    }

    let eleContainer = document.getElementById("navBarContainer");
    document.addEventListener("scroll", () => {
      document.documentElement.dataset.scroll = window.scrollY;
      if (document.documentElement.dataset.scroll !== "0" && !isToggleNav) {
        isToggleNav = true;
        if (window.location.pathname.length > 1) {
          eleContainer.classList.add("navBarContainer");
          eleContainer.classList.remove("navBarContainer-not-home");
          eleContainer.classList.add("top-0");
        } else {
          document.querySelector(".topnavbar").classList.add("border-bottom-gray");
        }
        eleContainer.classList.toggle("bg-color-white");
      } else if (
        document.documentElement.dataset.scroll === "0" &&
        isToggleNav
      ) {
        isToggleNav = false;
        if (window.location.pathname.length > 1) {
          eleContainer.classList.remove("navBarContainer");
          eleContainer.classList.add("navBarContainer-not-home");
        }else{
          document.querySelector(".topnavbar").classList.remove("border-bottom-gray");
        }
        eleContainer.classList.remove("bg-color-white");
      }
    });
    fnChangePath(!isChangePath);
  }, []);

  useEffect(() => {
    let eleHaveClass = document.querySelector(".txt-underline-nav-menu");
    if (eleHaveClass) {
      eleHaveClass.classList.remove("txt-underline-nav-menu");
    }
    document
      .getElementById(`menuType_${selTypeProduct}`)
      .classList.add("txt-underline-nav-menu");
  }, [selTypeProduct]);

  useEffect(() => {
    if (isChangePath) {
      let eleContainer = document.getElementById("navBarContainer");
      if (window.location.pathname.length > 1) {
        eleContainer.classList.remove("navBarContainer");
        eleContainer.classList.add("navBarContainer-not-home");
        document
          .querySelector(".topnavbar")
          .classList.add("border-bottom-gray");
      } else {
        eleContainer.classList.remove("navBarContainer-not-home");
        eleContainer.classList.add("navBarContainer");
        eleContainer.classList.remove("top-0");
        document
          .querySelector(".topnavbar")
          .classList.remove("border-bottom-gray");
      }
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      fnChangePath(!isChangePath);
      if (isShow) {
        fnIsShow(!isShow);
        fnSelectType(0);
      }
      let navMenuMobile = document.querySelector("#btnMenuOnMobile.collapsed");
      if (!navMenuMobile) {
        document.querySelector("#btnMenuOnMobile").click();
      }
    }
  }, [isChangePath]);

  useEffect(() => {
    let divAnimationProductNav = document.getElementById(
      "divAnimationProductNav"
    );

    if (isShow) {
      if (window.location.pathname.length == 1) {
        document.getElementById("navBarContainer").style.backgroundColor =
          "white";
      }
      document.documentElement.style.overflow = "hidden"; // firefox, chrome
      document.body.scroll = "no"; // ie only

      divAnimationProductNav.classList.add("contain-product-on-navbar-show");

      fnIconProduct(faChevronUp);
    } else {
      if (window.location.pathname.length == 1) {
        document.getElementById("navBarContainer").style.backgroundColor =
          "transparent";
      }
      document.documentElement.style.overflow = "auto"; // firefox, chrome
      document.body.scroll = "yes"; // ie only

      divAnimationProductNav.classList.remove("contain-product-on-navbar-show");
      fnIconProduct(faChevronDown);
    }
  }, [isShow]);

  return (
    <Container id="navBarContainer" fluid className="no-padding no-margin ">
      <Navbar className=" topnavbar" expand="sm">
        <Container fluid className="">
          <Navbar.Brand
            className="no-padding "
            as={Link}
            to="/"
            onClick={() => {
              fnChangePath(!isChangePath);
              if (isShow) {
                fnIsShow(!isShow);
              }
            }}
          >
            <ImgLogo src={Logo} className=" align-top" alt="Sahakit Logo" />
            <CompanyName className="">SAHAKIT</CompanyName>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            id="btnMenuOnMobile"
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Nav.Link
                className="navbar-menu"
                as={Link}
                to="/AboutUs"
                onClick={() => {
                  fnChangePath(!isChangePath);
                }}
              >
                <label className="txt-color-black">เกี่ยวกับเรา</label>
                <FontAwesomeIcon icon={faChevronDown} />
              </Nav.Link>
              <Nav.Link
                className="navbar-menu"
                onClick={() => fnIsShow(!isShow)}
              >
                <label className="txt-color-black">สินค้า</label>
                <FontAwesomeIcon icon={iconProduct} />
              </Nav.Link>
              <DropDownMobile
                className={isShow ? " active-div-mobile-product-navbar " : ""}
              >
                {selector_data_product.map((item, index) => {
                  let firstType = selector_data_sproduct.find(
                    (f) => f.MProductTypeId == item.MProductTypeId
                  );
                  if (firstType) {
                    return (
                      <a
                        href={`/ProductDetail?mp=${item.MProductTypeId}&sp=${firstType.SProductTypeId}`}
                        onClick={() => {
                          fnChangePath(!isChangePath);
                        }}
                        key={`ImgProduct_${index}`}
                        // onClick={() => fnSelectType(1)}
                        className="container-img-product"
                      >
                        <div>
                          <img
                            src={`${GetImageFile(item.MProductTypeImg)}`}
                            alt={""}
                          />
                        </div>
                        <label className="th-reg">
                          {item.MProductTypeName}
                        </label>
                        <hr></hr>
                      </a>
                    );
                  }
                })}
              </DropDownMobile>
              <Nav.Link
                className="navbar-menu"
                as={Link}
                to="/Service"
                onClick={() => {
                  fnChangePath(!isChangePath);
                }}
              >
                <label className="txt-color-black">บริการ</label>
                <FontAwesomeIcon icon={faChevronDown} />
              </Nav.Link>
              <Nav.Link
                className="navbar-menu"
                as={Link}
                to="/CSR"
                onClick={() => {
                  fnChangePath(!isChangePath);
                }}
              >
                <label className="txt-color-black">ข่าวสารและกิจกรรม</label>
                <FontAwesomeIcon icon={faChevronDown} />
              </Nav.Link>
              <Nav.Link
                className="navbar-menu"
                as={Link}
                to="/Contact"
                onClick={() => {
                  fnChangePath(!isChangePath);
                }}
              >
                <label className="txt-color-black">ติดต่อเรา</label>
                <FontAwesomeIcon icon={faChevronDown} />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container
        id="divAnimationProductNav"
        fluid
        className="contain-product-on-navbar-hide "
      >
        <BoxRowCategory>
          <div className="box-row-product">
            <div className="box-type">
              <ul>
                <li className="txt-align-right">
                  <span
                    onClick={() => fnSelectType(0)}
                    className="font-all-type"
                    name="txtTypeMenu"
                    id="menuType_0"
                  >
                    ทั้งหมด
                  </span>
                </li>
                {selector_data_product
                  ? selector_data_product.map((item) => {
                    let firstType = selector_data_sproduct.find(
                      (f) => f.MProductTypeId == item.MProductTypeId
                    );
                    if (firstType) {
                      return (
                        <li
                          key={item.MProductTypeId.toString()}
                          className="txt-align-right"
                        >
                          <span
                            onClick={() => fnSelectType(item.MProductTypeId)}
                            // className="font-type margin-left-20-px "
                            className="font-type"
                            name="txtTypeMenu"
                            id={"menuType_" + item.MProductTypeId.toString()}
                          >
                            {item.MProductTypeName}
                          </span>
                        </li>
                      );
                    }
                  })
                  : null}
              </ul>
            </div>
            <div className="box-img-product">
              {selTypeProduct === 0 ? (
                <div className="div-show-all-type">
                  {selector_data_product.map((item, index) => {
                    let firstType = selector_data_sproduct.find(
                      (f) => f.MProductTypeId == item.MProductTypeId
                    );
                    if (firstType) {
                      return (
                        <a
                          // to="/ProductDetail"
                          href={`/ProductDetail?mp=${item.MProductTypeId}&sp=${firstType.SProductTypeId}`}
                          onClick={() => {
                            fnChangePath(!isChangePath);
                            window.location.reload();
                          }}
                          key={`ImgProduct_${index}`}
                          // onClick={() => fnSelectType(1)}
                          className="container-img-product"
                        >
                          <div>
                            <img
                              src={`${GetImageFile(item.MProductTypeImg)}`}
                              alt={""}
                            />
                          </div>
                          <label className="th-reg">
                            {item.MProductTypeName}
                          </label>
                          {/* <hr></hr> */}
                        </a>
                      );
                    }
                  })}
                </div>
              ) : (
                selector_data_sproduct
                  .filter((f) => f.MProductTypeId == selTypeProduct)
                  .map((item, index) => (
                    <a
                      key={`spro_${index}`}
                      href={`/ProductDetail?mp=${item.MProductTypeId}&sp=${item.SProductTypeId}`}
                      // to={"/ProductDetail" + (item.Path ? "/" + item.Path : "")}
                      className="container-img-product"
                      onClick={() => {
                        fnChangePath(!isChangePath);
                      }}
                    >
                      <div>
                        <img
                          src={`${GetImageFile(item.SProductTypeImgMenu)}`}
                          alt=""
                        />
                      </div>
                      <label>{item.SProductTypeName}</label>
                      <hr></hr>
                    </a>
                  ))
              )}
            </div>
          </div>
        </BoxRowCategory>
      </Container>
    </Container>
  );
}
