import React, { useEffect, useState } from "react";
import { ProductDetailNav } from "./ProductDetailStyle";
import { Container, Row, Col, Table, Image } from "react-bootstrap";

export default function NavProduct({ SProduct }) {
  // export default function NavProduct({ SProduct, ListSProductType, choosedId }) {
  const [name, setname] = useState(() => {
    // var type = ListSProductType.find((f) => f.SProductTypeId == choosedId);
    // return type ? type.SProductTypeName : "";
    // return SProduct ? SProduct.SProductTypeName : "";
    return "";
  });

  const ScrollInfo = (eleId, offest) => {
    // document.getElementById(eleId).scrollIntoView({ behavior: 'smooth', block: 'center'});
    window.scrollTo({
      top: document.getElementById(eleId).offsetTop - offest,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // var type = ListSProductType.find((f) => f.SProductTypeId == choosedId);
    // if (type) {
    //   setname(type.SProductTypeName);
    // }
    setname(SProduct ? SProduct.SProductTypeName : "");
  }, [SProduct]);
  return (
    <Row>
      <ProductDetailNav>
        <div className="ps-2 ps-sm-0 box-content">
          <div className="div-text-head font-weight-bold">
            <span>{name}</span>
          </div>
          <div
            className="div-text"
            onClick={() => ScrollInfo("divScrollHeadInfo", 220)}
          >
            <span>ภาพรวม</span>
          </div>
          <div
            className="div-text"
            onClick={() => ScrollInfo("divScrollHeadSpec", 200)}
          >
            <span>รูปตัวอย่าง</span>
          </div>
          <div
            className="div-text"
            onClick={() => ScrollInfo("divScrollHeadDetail", 200)}
          >
            <span>ข้อมูลรถ</span>
          </div>
        </div>
      </ProductDetailNav>
    </Row>
  );
}
