import styled from "styled-components";

const media = {
    smaller: `@media (min-width:576px) and (max-width:1000px)`,
    big_mobile: `@media (min-width:576px) `,
    mobile: `@media (max-width:576px)`,
    tablet: `@media (max-width:992px)`,
};


export const Typemenu = styled.div`
flex-wrap: wrap;
justify-content: flex-start;
align-items: center;
width: 25%;
padding: 5px;
display:flex;


  .div-box-typetruck {
    min-height: 100px;
    border-radius: 0.25rem;
    text-align: center;
    border: 1px solid #d2d2d2;
    transition: all 0.3s;
    cursor: pointer;
    text-decoration: none;
  }

  ${media.mobile} {
    width: 100%;
  }

  .div-box-typetruck.choosed,
.div-box-typetruck:hover {
  //color: white;
  border: 5px rgb(42, 53, 156) solid;
  border: 5px  #ed7d31 solid;
}

.img-typetruck {
  min-width: 60%;
  object-fit: cover;
  max-height: 120px;
}
`;
