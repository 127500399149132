import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { ActivityBlock } from "./CSRStyle";
import {
  convertDateFromApi,
  // convertDateTimeToString2Digit,
  convertDateTimeToStringDate,
  GetImageFile,
} from "../../global/GlobalFunction";
const CreateContentActivity = (objData, idx) => {
  const dNewsDate = convertDateFromApi(objData.NewsDate);
  const sNewsDate = dNewsDate ? convertDateTimeToStringDate(dNewsDate) : "";
  return (
    <ActivityBlock key={idx}>
      <Container className={!(idx % 2) ? "bg-lightshadow" : ""}>
        <Row className={idx % 2 ? "revert-div" : ""}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Container className="img-block">
              <Image className="img" src={GetImageFile(objData.NewsImg)} />
            </Container>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Container className="content-block">
              <h2 className="txt-color-primary txt-weight-bold">
                {objData.NewsHead}
              </h2>
              <p style={{ whiteSpace: "pre-line" }}>
                &emsp;&emsp;{objData.NewsDesc}
              </p>
              {/* <p style={{ whiteSpace: "pre-line" }}>
                &emsp;&emsp;{objData.NewsDesc2}
              </p> */}
              <p
                className="txt-color-primary fw-bold"
                style={{ fontSize: 16, textAlign: "right" }}
              >
                {objData.NewsHastag}
              </p>
              <p style={{ fontSize: 12, textAlign: "right" }}>
                {"วันที่ "}
                {sNewsDate}
              </p>
            </Container>
          </Col>
        </Row>
      </Container>
    </ActivityBlock>
  );
};

export function ActivityContent(props) {
  const { lstData } = props;

  return lstData.map((data, idx) => CreateContentActivity(data, idx));
}
