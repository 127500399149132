import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Container, Row, Col, Image, Accordion, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Typemenu } from "./TypemenuStyle";
import { GetImageFile } from "../../global/GlobalFunction";

export default function TypeMenu({ ListSProductType, setIsload, choosedId }) {
  // let picarr = [SteCon, SteSand, AluSan, AluAgr, SteAgr];

  // useEffect(() => {
  //   var lstLvlone = document.querySelectorAll(".lvl-one");
  //   var lstLvltwo = document.querySelectorAll(".lvl-two");
  //   lstLvlone.forEach((ele, idx) => {
  //     ele.addEventListener("mouseenter", (e) => {
  //       lstLvltwo.forEach((subitem) => {
  //         subitem.style.display = "none";
  //       });

  //       lstLvlone.forEach((subitem) => {
  //         subitem.firstElementChild.style.backgroundColor = "unset";
  //       });
  //       lstLvltwo[idx].style.display = "block";
  //     });
  //   });

  //   lstLvltwo.forEach((ele, idx) => {
  //     ele.addEventListener("mouseenter", (e) => {
  //       lstLvlone[idx].firstElementChild.style.backgroundColor = "white";
  //     });

  //   });
  // }, []);
  // let { type } = useParams();

  // const DivTypeTruckLayout = ({ pic, isChoosed, typename, linktag, idx }) => {
  //   console.log("load");
  //   return (
  //     <Typemenu>
  //       <Link
  //         to={"/ProductDetail/" + linktag}
  //         onClick={() => {
  //           setIdx(idx);
  //         }}
  //         style={{ width: "100%", textDecoration: "none", color: "#ed7d31" }}
  //         className=""
  //       >
  //         <div className={"div-box-typetruck " + (isChoosed ? "choosed" : "")}>
  //           <Image className="img-typetruck" src={atob(pic)} />
  //           <h6 className="margin-top-10-px th-reg">
  //             {typename ? typename : "ประเภท"}
  //           </h6>
  //         </div>
  //       </Link>
  //     </Typemenu>
  //   );
  // };

  // useEffect(() => {
  //   console.log("loadddddd");
  // }, []);

  return (
    <Container className="margin-vertical-40px">
      <Accordion defaultActiveKey="0">
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            style={{
              backgroundColor: "#f3a875",
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <h5 className="m-0 th-reg text-white">รายการประเภท</h5>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div
              id="divTypeTruck"
              className="display-flex overflow-auto padding-bottom-10-px"
            >
              {ListSProductType.length > 0
                ? ListSProductType.map((item, idx) => {
                    return (
                      // <h1>{item.SProductTypeName}</h1>
                      <Typemenu key={`typemenu_${item.SProductTypeId}`}>
                        <Link
                          to={`/ProductDetail?mp=${item.MProductTypeId}&sp=${item.SProductTypeId}`}
                          onClick={() => {
                            setIsload();
                          }}
                          style={{
                            width: "100%",
                            textDecoration: "none",
                            color: "#ed7d31",
                          }}
                          className=""
                        >
                          <div
                            className={
                              "div-box-typetruck " +
                              (choosedId == item.SProductTypeId
                                ? "choosed"
                                : "")
                            }
                          >
                            <Image
                              className="img-typetruck"
                              src={GetImageFile(item.SProductTypeImgMenu)}
                            />
                            <h6 className="margin-top-10-px th-reg">
                              {item.SProductTypeName
                                ? item.SProductTypeName
                                : "ประเภท"}
                            </h6>
                          </div>
                        </Link>
                      </Typemenu>
                      // <DivTypeTruckLayout
                      //   key={`typemenu_${item.SProductTypeId}`}
                      //   pic={item.SProductTypeImgMenu}
                      //   typename={item.SProductTypeImgMenu}
                      //   isChoosed={false
                      //     // type === truckType.tenSteCons || !type ? true : false
                      //   }
                      //   linktag={`/?mp=${item.MProductTypeId}&sp=${item.SProductTypeId}`}
                      //   idx={item.SProductTypeId}
                      // />
                    );
                  })
                : null}
              {/* 
              <DivTypeTruckLayout
                pic={picarr[0]}
                typename={truckTypeString[0]}
                isChoosed={
                  type === truckType.tenSteCons || !type ? true : false
                }
                linktag={truckType.tenSteCons}
                idx={0}
              />
              <DivTypeTruckLayout
                pic={picarr[1]}
                typename={truckTypeString[1]}
                isChoosed={type === truckType.tenSteSand ? true : false}
                linktag={truckType.tenSteSand}
                idx={1}
              />
              <DivTypeTruckLayout
                pic={picarr[2]}
                typename={truckTypeString[2]}
                isChoosed={type === truckType.tenAluSand ? true : false}
                linktag={truckType.tenAluSand}
                idx={2}
              />
              <DivTypeTruckLayout
                pic={picarr[3]}
                typename={truckTypeString[3]}
                isChoosed={type === truckType.tenAluAgr ? true : false}
                linktag={truckType.tenAluAgr}
                idx={3}
              />
              <DivTypeTruckLayout
                pic={picarr[4]}
                typename={truckTypeString[4]}
                isChoosed={type === truckType.tenSteAgr ? true : false}
                linktag={truckType.tenSteAgr}
                idx={4}
              /> */}

              {/* <DivTypeTruckLayout pic={picarr[2]} typename={textarr[2]} />
        <DivTypeTruckLayout pic={picarr[3]} typename={textarr[3]} /> */}
            </div>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Container>
  );
}
