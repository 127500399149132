import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import anime from "animejs/lib/anime.es.js";
import BoxVideoContent from "../component/ContentComponent/Box_VideoAndContent";
import TruckList from "../component/About/TruckList";

export default function AboutUsPage() {
  useEffect(() => {
    anime({
      targets: "#bodyAbout",
      opacity: "100%",
      easing: "linear",
    });
  }, []);

  return (
    <Container
      id="bodyAbout"
      fluid
      style={{ paddingTop: "100px", minHeight: "71.5vh" }}
      className="margin-bottom-content no-margin "
    >
      {/* <Row className="margin-vertical-20px padding-30-px">
        <Col xs={12} sm={6} className="txt-align-center">
          <h1 className="txt-color-primary txt-weight-bold">เกี่ยวกับเรา</h1>
        </Col>
      </Row> */}
      <Row className="padding-side-10-percent mt-1 mb-2 padding-30-px flex-warp-reverse-mobile-only ">
        <BoxVideoContent>
          <div className="Video-Content">
            <iframe
              title="SHK Video"
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fsahakit1989%2Fvideos%2F290627182223775%2F&width=500&show_text=false&appId=129918181731575&height=280"
              frameBorder="0"
              allow="autoplay"
              // onLoad={() => {
              //   console.log("load");
              // }}
            />
          </div>
          <h1 className="mt-5 txt-align-center txt-color-primary">
            ประวัติโดยย่อของอู่สหะกิจ
          </h1>
          <div className="Text-Content">
            บริษัท อู่สหะกิจ (1989) จำกัด ก่อตั้งเมื่อ วันที่ 29 มิถุนายน 2532
            ทางอู่สหะกิจ ได้ดำเนินงาน จำหน่าย ต่อ ประกอบรถหัวลาก ต่อกระบะ
            รถกระบะบรรทุก และรถพ่วงบรรทุกทุกประเภท รวมถึงงานบริการด้านการทำ สี
            การซ่อมช่วงล่างและตัวถัง เป็นระยะเวลากว่า 30
            ปีที่ให้บริการกับลูกค้าในหลากหลายจังหวัด ทั่วทุก ภูมิภาค
            อย่างต่อเนื่อง งานประกอบรถบรรทุก และงานซ่อมทุกประเภทของทางอู่สหะกิจ
            ทางเราให้ความสำคัญกับ คุณภาพของ ผลงานของเรามาเป็นอันดับ 1
            เราพิถีพิถันในการสร้างสรรค์ผลงานที่มีคุณภาพ ตั้งแต่การออกแบบการ
            เลือกสรรวัสดุ ตลอดจนการผลิต
            เพื่อให้ได้มาซึ่งผลงานชิ้นเอกก่อนส่งมอบถึงมือลูกค้าทุกท่าน
            เพื่อให้ตรงกับวิสัยทัศน์ขององค์กรที่ว่า เชื่อมั่นในคุณภาพ
            เชื่อมั่นในอู่สหะกิจ
          </div>
        </BoxVideoContent>
      </Row>
      <Row className="justify-content-center mb-3">
        <h1 className="mt-5 txt-align-center txt-color-primary">
          ตัวอย่างผลงานกระบะบรรทุกต่างๆ
        </h1>
        <TruckList />
      </Row>
    </Container>
  );
}
