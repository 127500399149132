import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faFacebookF,
  faLine,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { GetOs } from "../../global/GlobalFunction";
export default function IconRightBottomPage() {
  const toggleIcon = () => {
    let lstEle = document.querySelectorAll(".div-icon-right");
    lstEle.forEach((item) => {
      item.classList.toggle("class-toggle-icon-right");
    });
  };

  const phoneClick = () => {
    let eleLabel = document.querySelector(".divLblPhoneNumber");
    eleLabel.classList.toggle("divLbl-right-show");
    // let os = GetOs();
    // if (os === "Android" || os === "iOS") {
    //   window.open("tel:+6636371011");
    // }
    window.open("tel:+6636371011");
  };

  const facebookHover = () => {
    let eleLabel = document.querySelector(".divLblFacebook");
    eleLabel.classList.toggle("divLbl-right-show");
  };

  const LineHover = () => {
    let eleLabel = document.querySelector(".divLblLine");
    eleLabel.classList.toggle("divLbl-right-show");
  };

  // const IgHover = () => {
  //   let eleLabel = document.querySelector(".divLblIg");
  //   eleLabel.classList.toggle("divLbl-right-show");
  // };
  return (
    <div className="container-rigth-page">
      {/* <div
        className="div-icon-right icon-rigth-ig"
        onMouseEnter={IgHover}
        onClick={() => window.open("https://www.instagram.com/")}
      >
        <FontAwesomeIcon icon={faInstagram} className="icon-right  " />
      </div>
      <div className="divLblIg divLbl-rightIcon">
        <label>Instagram</label>
      </div>*/}
      <div
        className="div-icon-right icon-rigth-line"
        onMouseEnter={LineHover}
        onMouseLeave={() => {
          document
            .querySelector(".divLblLine")
            .classList.remove("divLbl-right-show");
        }}
        onClick={() => window.open("https://lin.ee/3NsXCD1")}
      >
        <FontAwesomeIcon
          icon={faLine}
          className="icon-right "
          // size={14}
          style={{ fontSize: "32px" }}
          // style={{ width: "75%", height: "auto" }}
        />
      </div>
      <div className="divLblLine divLbl-rightIcon">
        <label>@Sahakit</label>
      </div>
      <div
        className="div-icon-right icon-rigth-fb"
        onClick={() => window.open("https://www.facebook.com/sahakit1989")}
        onMouseEnter={facebookHover}
        onMouseLeave={() => {
          document
            .querySelector(".divLblFacebook")
            .classList.remove("divLbl-right-show");
        }}
      >
        <FontAwesomeIcon
          icon={faFacebookF}
          className="icon-right "
          // size={14}
          style={{ fontSize: "28px" }}
          // style={{ width: "75%", height: "auto" }}
        />
      </div>
      <div className="divLblFacebook divLbl-rightIcon">
        <label>Sahakit - อู่สหะกิจ หนองแค</label>
      </div>
      <div
        className="div-icon-right icon-rigth-phone"
        onClick={phoneClick}
        onMouseEnter={phoneClick}
        onMouseLeave={() => {
          document
            .querySelector(".divLblPhoneNumber")
            .classList.remove("divLbl-right-show");
        }}
      >
        <FontAwesomeIcon
          icon={faPhoneAlt}
          className="icon-right "
          // size={"1x"}
          style={{ fontSize: "24px" }}
        />
      </div>
      <div className="divLblPhoneNumber divLbl-rightIcon">
        <label>036-371-011, 036-370351-55</label>
      </div>
      <div className="div-icon-right icon-rigth-menu" onClick={toggleIcon}>
        <FontAwesomeIcon icon={faEllipsisH} className="icon-right " />
      </div>
    </div>
  );
}
