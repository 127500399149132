import { SHOW_LOADING, HIDE_LOADING, SET_DATA_NEWS, SET_DATA_PRODUCT_MENU, SET_DATA_SPRODUCT_MENU } from './actions_type'

const initialState = {
    loading: false,
    data_news: [],
    data_product_menu: [],
    data_sproduct_menu: []
};

function rootReducer(state = initialState, action) {

    switch (action.type) {
        case SHOW_LOADING:
            return { ...state, loading: true }
        case HIDE_LOADING:
            return { ...state, loading: false }
        case SET_DATA_NEWS:
            return { ...state, data_news: action.payload.lstdata }
        case SET_DATA_PRODUCT_MENU:
            return { ...state, data_product_menu: action.payload.lstdata }
        case SET_DATA_SPRODUCT_MENU:
            return { ...state, data_sproduct_menu: action.payload.lstdata }
        default:
            break;
    }
    return state;
};

export default rootReducer;