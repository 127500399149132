import axios from "axios";
const privateKey = "lwNsHWnkFALOvRjfsDgZjBrbmGhrhu"
export const AxiosPost = async (
  sWebMetodName,
  objJSON,
  fnSuccess,
  fnError,
  fnComplete
) => {
  let localJwtKey = process.env.REACT_APP_JWT_KEY;
  let auth_token =
    localStorage.getItem(localJwtKey) != null
      ? localStorage.getItem(localJwtKey)
      : undefined;
  let ConfigJwt = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Company-Token": `${localJwtKey}`,
    Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${privateKey}.${auth_token}`,
  };

  let sPathApi = process.env.REACT_APP_API_URL + "api/" + sWebMetodName;

  await axios
    .post(sPathApi, objJSON, { headers: ConfigJwt })
    .then((res) => {
      switch (res.status) {
        case 200:
          if (fnSuccess) fnSuccess(res.data);
          break;
        case 401:
          Unauthorize();
          break;
        case 500:
          break;
      }
    })
    .catch((errors) => {
      if (fnError) {
        fnError(errors);
      } else {
        console.error(errors);
      }
    })
    .finally(() => {
      if (fnComplete) fnComplete();
    });
};

export const AxiosGet = (
  sWebMetodName,
  objJSON,
  fnSuccess,
  fnError,
  fnComplete
) => {
  let auth_token = process.env.REACT_APP_TOKEN;
  let ConfigJwt = {
    Authorization: `bearer ${auth_token}`,
  };
  let sPathApi = process.env.REACT_APP_API_URL + "api/" + sWebMetodName;
  axios
    .get(sPathApi, {
      headers: ConfigJwt,
      params: objJSON,
      paramsSerializer: (params) => {
        return JSON.stringify(params);
      },
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          if (fnSuccess) fnSuccess(res.data);
          break;
        case 401:
          Unauthorize();
          break;
        case 500:
          break;
        default:
          break;
      }
    })
    .catch((errors) => {
      ResultAPI(errors, "");
    })
    .finally(() => {
      if (fnComplete) fnComplete();
    });
};

export const AxiosGetAsync = async (
  sWebMetodName,
  objJSON,
  fnSuccess,
  fnError,
  fnComplete
) => {
  let auth_token = process.env.REACT_APP_TOKEN;
  let ConfigJwt = {
    Authorization: `bearer ${auth_token}`,
  };

  let sPathApi = process.env.REACT_APP_API_URL + "api/" + sWebMetodName;

  const response = await axios.get(sPathApi, {
    headers: ConfigJwt,
    params: objJSON,
  });
  return response.data;
};

export const AxiosPost_APIAsync = async (
  sWebMetodName,
  objJSON,
  fnSuccess,
  fnError,
  fnComplete
) => {
  let localJwtKey = process.env.REACT_APP_JWT_KEY;
  let auth_token =
    localStorage.getItem(localJwtKey) != null
      ? localStorage.getItem(localJwtKey)
      : undefined;
  let ConfigJwt = {
    Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${privateKey}.${auth_token}`,
  };

  let sPathApi = process.env.REACT_APP_API_URL + "api/" + sWebMetodName;

  const response = await axios.get(sPathApi, {
    headers: ConfigJwt,
    params: objJSON,
  });
  return response.data;
};

export const ResultAPI = (response, MSG_Success, fnOnSuccess, fnOther) => {
  let { sStatus, sMsg, nPermission } = response;

  if (sStatus === "200") {
    if (!nPermission) nPermission = 1;
    // if (nPermission === 0) {
    //   // NoPermission();
    // } else {
    //   // if (!IsLoadData) {
    //   //   if (!MSG_Success) MSG_Success = AlertMsg.SaveComplete;
    //   //   SwAlert.Success(AlertTitle.Info, MSG_Success, fnOnSuccess);
    //   // } else {
    //   //   if (fnOnSuccess) fnOnSuccess();
    //   // }
    // }
  } else if (sStatus === "401") {
    // SwAlert.Warning(AlertTitle.Warning, AlertMsg.SessionTimeOut, function () {
    //   var sPathSite = process.env.REACT_APP_API_URL as string;
    //   var sFullPath = window.location.origin  + window.location.pathname;
    //   var sPath = sFullPath.replace(sPathSite+"/", "");
    //   var sSearch = window.location.search;
    //   if (sSearch !== "") {
    //     sSearch = sSearch.replace("?", "&&");
    //   }
    //   var sPage = sPathSite + "?sPage=" + sPath + sSearch;
    //   window.location.href =  sPage;
    // });
  } else if (sStatus === "403") {
    // SwAlert.Warning(AlertTitle.Warning, AlertMsg.SessionTimeOut, function () {
    //   var sPathSite = process.env.REACT_APP_API_URL as string;
    //   var sFullPath = window.location.origin  + window.location.pathname;
    //   var sPath = sFullPath.replace(sPathSite+"/", "");
    //   var sSearch = window.location.search;
    //   if (sSearch !== "") {
    //     sSearch = sSearch.replace("?", "&&");
    //   }
    //   var sPage = sPathSite + "?sPage=" + sPath + sSearch;
    //   window.location.href =  sPage;
    // });
  } else if (sStatus === "409") {
    // let tempMsg = AlertMsg.Duplicate;
    // if (!sMsg) {
    //   sMsg = tempMsg;
    // }
    // SwAlert.Error(AlertTitle.Warning, sMsg, () => {
    //   if (fnOther) fnOther();
    // });
  } else {
    if (fnOnSuccess) fnOnSuccess();
  }
};

export const IsNullOrUndefined = (Value) => {
  let Result = true;
  if (Value) {
    Result = false;
  }
  return Result;
};

export const Unauthorize = () => { };

