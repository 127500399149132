import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import HomePage from "./HomePage";
import AboutUs from "./AboutUsPage";
import Service from "./ServicePage";
import Contact from "./ContactPage";
import ProductDetail from "./ProductDetailPage";
import CSR from "./CSRPage";
import Navbar from "../component/Navbar/navbarComponent";
import Footer from "../component/Footer/Footers";
import IconRightPage from "../component/IconRightBottomPage/IconRightBottomPage";
import { AxiosGetAsync, AxiosGet } from "../services/api/main";
import { useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay';
export default function Navigate() {
  const isLoading = useSelector((state) => state.loading);
  const PageLayout = (Content) => {
    return (
      <React.Fragment>
        <LoadingOverlay
          active={isLoading}
          spinner
          text='Loading'
        >
          <Navbar />
          <Content />
          <IconRightPage />
          <Footer />
        </LoadingOverlay>
      </React.Fragment>
    );
  };
  return (
    <Switch>
      <Route exact path="/">
        {PageLayout(HomePage)}
      </Route>
      <Route path="/AboutUs">{PageLayout(AboutUs)}</Route>
      <Route path="/Service">{PageLayout(Service)}</Route>
      <Route path="/Contact">{PageLayout(Contact)}</Route>
      <Route path="/CSR">{PageLayout(CSR)}</Route>
      <Route exact path="/ProductDetail">
        {PageLayout(ProductDetail)}
      </Route>
      {/* <Route path="/ProductDetail/:type">{PageLayout(ProductDetail)}</Route> */}
    </Switch>
  );
}
