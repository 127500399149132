import styles from "styled-components";

const media = {
  mobile: `@media(max-width:575px)`,
  xxlg: `@media(min-width:1440px)`,
  xlg: `@media(min-width:1244px)`,
  lg: `@media(min-width:1024px)`,
  md: `@media(min-width:768px)`,
  s: `@media(min-width:480px)`,
  xs: `@media(min-width:320px)`,
};

export const ImgMain = styles.img`
  padding:0;
  height:100vh;
  object-fit: cover;
  width:100%;
  display:block;
  object-position: top;
  ${media.mobile} {
      height:26vh;
  }
  ${media.s}{
    height:42vh;
  }
  ${media.md} {
    height:42vh;
  }
  ${media.lg} {
    height:42vh;
  }
  ${media.md} and (orientation: landscape) {
    height:100vh;
  }
  @media(max-width:1024px) and (orientation: landscape) {
    height:75vh;
  }
`;    
