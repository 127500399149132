import styled from "styled-components";

const media = {
  smaller: `@media (min-width:576px) and (max-width:1000px)`,
  big_mobile: `@media (min-width:576px) `,
  mobile: `@media (max-width:576px)`,
  tablet: `@media (max-width:992px)`,
  tablet_min_max: `@media (max-width:992px) and (min-width:767px)`,
  mobile_min_max: `@media (min-width:576px) and (max-width:766px)`,
};

export const DropDownMobile = styled.div`
  display: none;
  ${media.mobile} {
    display: flex;
    flex-direction: row;
    align-items: start;
    height: 0;
    width: 100%;
    transition: height 0.5s;
    overflow: auto;
  }
  .row {
    margin: 10px 0;
    text-decoration: none;
  }
  label {
    text-align: center;
    width: 100%;
    font-size: 20px;
    color: black;
  }
  .contain-img {
    display: flex;
    justify-content: center;
    img {
      width: 80%;
      object-fit: contain;
    }
  }
`;

export const CompanyName = styled.label`
  margin-top: 7%;
  margin-left: 5%;
  font-size: 28px;
  font-weight: bold;
  font-family: Helvetica Neue !important;
  //font-family: Arial Black !important;
  color: #c13e20;
  letter-spacing: 3px;
  -webkit-text-stroke: #c13e20;
  -webkit-text-stroke-width: 2px;
  cursor: pointer;
  ${media.tablet_min_max} {
    margin-top: 7%;
    font-size: 16px;
  }
  ${media.mobile_min_max} {
    margin-top: 7%;
    font-size: 18px;
  }
  ${media.mobile} {
    margin-top: 3%;
    font-size: 30px;
  }
`;

export const ImgLogo = styled.img`
  width: 90px;
  height: 80px;
  margin-left: 15vh;

  ${media.big_mobile} {
    margin-left: 0;
  }

  ${media.mobile} {
    margin-left: 0;
    width: 60px;
    height: 60px;
  }
  ${media.tablet_min_max} {
    width: 55px;
    height: 50px;
  }
  ${media.mobile_min_max} {
    width: 50px;
    height: 50px;
  }
`;

export const BoxRowCategory = styled.div`
  width: 100%;
  height: 100%;
  padding: 2%;
  overflow: hidden;

  .div-show-all-type {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .font-all-type {
    font-size: 24px;
    cursor: pointer;
  }
  .font-type {
    font-size: 20px;
    position: relative;
    text-decoration: none !important;
    cursor: pointer;
  }

  .font-type::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  .font-type:hover::before {
    transform: scaleX(1);
  }

  .box-row-product {
    display: flex;
    height: 90%;
    overflow: hidden;
  }

  .box-type {
    display: flex;
    flex: 1;
    padding: 2%;
    overflow: hidden;
    border-right: 1px black solid;
  }

  .box-img-product {
    flex: 3;
    padding: 10px;
    overflow: auto;
    align-items: start;
    display: flex;
    flex-wrap: wrap;
  }

  .container-img-product {
    width: 40%;
    height: auto;
    cursor: pointer;
    margin: 10px 10px;
    overflow: hidden;
    border: 1px #80808050 solid;
    border-radius: 0.25rem;
    div {
      overflow: hidden;

      img {
        height: 30vh;
        object-fit: contain;
        padding: 5px;
        width: 100%;
        transition: 0.5s all ease-in-out;
      }
    }

    label {
      text-align: center;
      width: 100%;
      padding: 10px 0;
      transition: 0.5s all ease-in-out;
    }
  }
  hr {
    margin: 0px;
    height: 5px;
    width: 0px;
    background-color: red;
    transition: width 0.5s;
  }

  .container-img-product:hover {
    img {
      background-color: white;
    }
    label {
      background-color: white;
    }
    hr {
      width: 100%;
    }
  }

  .container-img-product:hover img {
    padding: 0px !important;
  }

  a {
    color: black;
    text-decoration: none;
    transition: text-decoration 0.5s;
    cursor: pointer;
  }
  a:hover {
    text-decoration: underline;
  }

  ul {
    width: 100%;
    // padding-left: 40%;
  }

  ${media.tablet} {
    ul {
      padding-left: 0%;
    }
  }
  li {
    list-style: none;
    margin: 10px 0;
  }

  ${media.smaller} {
    .font-all-type {
      font-size: 20px;
    }
    .font-type {
      font-size: 16px;
    }
    .container-img-product {
      width: 40%;
    }
  }
  ${media.mobile} {
    .container-img-product {
      div {
        img {
          width: 100%;
        }
      }
     
    }
  } 
`;
