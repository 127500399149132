import React, { useEffect } from "react";
import { useHistory } from "react-router";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  FormLabel,
  Card,
} from "react-bootstrap";
// import { ImgMain } from "../component/Home/ContainerImg";
import PicSlide from "../component/Home/PicMainSlide";
import MainBand from "../img/Home_Img.jpg";
// import ImgService from "../img/service.jpg";
import ServPic5 from "../img/ServicesPic/service5.jpg";
import fourtruck from "../img/fourTruck.png";
import "../style/main.scss";
import anime from "animejs/lib/anime.es.js";

export default function HomePage() {
  const history = useHistory();

  useEffect(() => {
    anime({
      targets: "#bodyHome",
      opacity: "100%",
      easing: "linear",
    });
  }, []);

  return (
    <Container
      id="bodyHome"
      fluid
      className="margin-bottom-content no-margin"
      style={{ minHeight: "71.5vh" }}
    >
      <Row className="">
        <PicSlide />
        {/* <ImgMain src={MainBand} /> */}
      </Row>

      <Container style={{ display: "none" }} className="content-one">
        <Row className="padding-30-px" style={{ minHeight: "550px" }}>
          <Col xs={12} sm={12} md={8} lg={8}>
            <div className="content-one-img-box">
              <div className="content-one-bg"></div>
              <Image className="content-one-img" src={MainBand} />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <Container
              className="no-padding"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h1 className="txt-weight-bold txt-color-primary">
                สินเชื่อรถบรรทุก
              </h1>
              <p className="font-size-20">
                บริษัทฯ เสนอหลากหลายบริการทางการเงิน
                ที่มีทางเลือกในการวางเงินดาวน์ และการผ่อนชำระที่เหมาะสมกับลูกค้า
                สนใจสอบถามรายละเอียดเพิ่มเติมได้ที่ 036-371-011 หรือ 036-371-849
              </p>
              <Button className="margin-top-10-px btn-primary font-size-20">
                Read More
              </Button>
            </Container>
          </Col>
        </Row>
      </Container>

      <Container>
        <h1 className="mt-5 txt-color-primary">การต่อประกอบและทำสี </h1>

        <Row className="p-3 flex-warp-reverse-mobile-only">
          <Image
            className=" no-padding object-fit-fill border-radius-10-px "
            src={fourtruck}
            fluid
            style={{ objectFit: "cover" }}
          />
        </Row>

        <Row className="p-3 pt-0 mb-5 flex-warp-reverse-mobile-only">
          <Col xs={12} sm={12}>
            <Container className="pt-0 text-center border-radius-10-px padding-15-px">
              <p className="home-content-item">
                อู่สหะกิจรับต่อประกอบและทำสีรถบรรทุกหลากหลายประเภท
                ทั้งกระบะบรรทุกพ่วง ดัมพ์ กระบะเหล็ก กระบะอลูมิเนียม
                สำหรับการใช้งานที่หลากหลาย ไม่ว่าจะเป็น บรรทุกหินทราย
                พืชผลทางการเกษตร การก่อสร้าง
                บรรทุกเพื่อการขนส่งและอื่นๆอีกมากมาย
              </p>
            </Container>
          </Col>
          {/* <Col xs={12} sm={6} className="txt-align-center">
            <Image
              className=" no-padding object-fit-fill border-radius-10-px margin-bottom-10-px"
              src={ImgService}
              fluid
              style={{ height: "300px" }}
            />
          </Col> */}
        </Row>

        <h1 className="txt-color-primary">บริการงานรถซ่อม</h1>

        <Row className="margin-vertical-20px padding-30-px">
          <Col xs={12} lg={6} className="txt-align-center">
            <Image
              className=" no-padding margin-bottom-10-px border-radius-10-px object-fit-fill"
              style={{
                maxHeight: "300px",
              }}
              src={ServPic5}
              fluid
            />
          </Col>
          <Col xs={12} lg={6}>
            <Container className="bg-color-lightshadow border-radius-10-px padding-15-px">
              {/* <h1 className="txt-color-primary">บริการงานรถซ่อม</h1> */}
              <p className="home-content-item">
                บริษัทฯ ให้บริการหลังการขายและ งานซ่อมบำรุงอย่างครบวงจร
                รวมถึงงานเคลมประกัน เพื่อสร้างคุณภาพความปลอดภัย ความน่าเชื่อถือ
                และความคุ้มครองที่ดีที่สุดให้กับรถบรรทุกของคุณ
                เรามีแผนประกันภัยที่เหมาะสม และได้มาตรฐาน
                จากบริษัทวิริยะประกันภัย ที่มีความคุ้มครองอย่างรอบด้านใน
                กรณีที่รถของคุณเกิดอุบัติเหตุ
              </p>
              {/* <Button className="margin-top-10-px btn-primary" onClick={() => history.push('/Service')}> 
                รายระเอียดเพิ่มเติม
              </Button> */}
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
